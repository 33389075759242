@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	.text-colorSettings,
	li,
	td,
  a {
		@apply text-primaryLight dark:text-primaryDark transition-colors duration-300;
	}
  h1,h2,h3{
    @apply font-pirata
  }
  h4,h5,h6,p, span{
    @apply font-pd
  }

	.h1 {
		@apply text-5xl md:text-6xl lg:text-7xl xl:text-8xl;
	}


	.h2 {
		@apply text-3xl md:text-4xl lg:text-5xl xl:text-6xl;
	}

	
	.h3 {
		@apply text-2xl md:text-3xl lg:text-4xl xl:text-5xl;
	}


	.h4 {
		@apply text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold;
	}

	.invertColors  {
		@apply text-mainWhite dark:text-mainBlack;
	}
}

@layer components {
	.btnBaseStyle {
		@apply cursor-pointer w-full py-02 rounded flex justify-center items-center transition duration-300 hover:scale-110 hover:shadow-lg hover:shadow-darkestRed dark:hover:shadow-darkestCyan;
	}

	.btnPrimary {
		@apply bg-primaryLight dark:bg-primaryDark disabled:opacity-50;
	}

	.btnSecondary {
		@apply bg-mainWhite dark:bg-mainBlack disabled:opacity-40;
	}

	.btnTertiary {
		@apply bg-none border-2 border-primaryLight dark:border-primaryDark;
	}

	.btnBaseText {
		@apply font-pirata h4 font-normal;
	}

	.btnPrimaryText {
		@apply text-mainWhite dark:text-mainBlack;
	}

	.btnSecondaryText {
		@apply text-primaryLight dark:text-primaryDark;
	}
}

.realistic-marker-highlight {
	position: relative;
}

.realistic-marker-highlight:before {
	content: "";
	width: 105%;
	height: 5.5em;
	position: absolute;
	z-index: -1;
	filter: url(#marker-shape);
	left: -0.25em;
	top: 0.1em;
	padding: 0 0.25em;
}